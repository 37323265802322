import { useTranslation } from 'react-i18next'
import { BottomWrap, CloseBtn, ModalproductsWrap, ProductsList } from './styled'
import { TFilterParams, TModalproductsProps } from './types'
import { memo, useEffect, useState } from 'react'
import { useTypedSelector } from 'hooks'
import { useGetInfiniteProduct } from 'store'
import { ProductFilterBar, ProductItem } from './components'
import { TProduct } from 'store/product/types'
import { i18n } from 'config'
import locales from 'enums/locales'
import { RelativePreloader } from 'components'
import { PreloaderContainer } from 'components/SelectProductPopup/styled'
import { defaultParams, useGetSimilars } from './helpers'
import { useInView } from 'react-intersection-observer'
import { ButtonSave } from '../../styled'

export const Modalproducts = memo(
   ({ products, productType, setProducts, close, watch }: TModalproductsProps) => {
      const { t } = useTranslation()
      const { accessToken } = useTypedSelector((state) => state.user)
      const [selectedProduct, setSelectedProduct] = useState<TProduct | null>(null)
      const [isInitialLoad, setIsInitialLoad] = useState(true)
      const [params, setParams] = useState<TFilterParams>({
         ...defaultParams,
         category: watch(`nameCategory`)
      })

      const mainCategoryId = watch(`nameCategory`)?.value

      const { ref, inView } = useInView({
         threshold: 0.1
      })

      const { data, isLoading, setSize, mutate } = useGetInfiniteProduct({
         params: {
            limit: 20,
            lang: i18n.language as locales,
            order: params.order,
            sortBy: params.sortBy,
            query: params.query || undefined,
            date_start: params.date_start,
            date_end: params.date_end,
            token: accessToken || '',
            category: params.category?.value ? [params.category?.value] : undefined,
            page: 1
         },
         revalidateOnMount: params.category?.value !== mainCategoryId
      })
      // get similar products

      const { similarData, similarLoading } = useGetSimilars({
         token: accessToken || '',
         lang: i18n.language as locales,
         _id: products[0]?.product._id || ''
      })

      useEffect(() => {
         if (inView) {
            setSize((prevSize) => prevSize + 1)
         }
      }, [inView])

      // clear params before exit
      useEffect(() => {
         return () => {
            setSize(0)
            setSelectedProduct(null)
            setParams(defaultParams)
         }
      }, [])

      const onSelectedProductClick = (product: TProduct) => {
         setSelectedProduct(product)
      }

      const onAddClick = () => {
         if (productType && selectedProduct) {
            setProducts((prev) => [
               ...prev,
               { type: productType, product: selectedProduct }
            ])
            close()
         }
      }

      const loading = isLoading || similarLoading
      // const { processedData } = useGetSimilarData(products, similarData)

      return (
         <ModalproductsWrap>
            <h2>{t('addProduct')}</h2>
            <ProductFilterBar
               visibleCategory={isInitialLoad && !products.length}
               setIsInitialLoad={setIsInitialLoad}
               mutate={mutate}
               watch={watch}
               params={params}
               setParams={setParams}
            />

            <ProductsList>
               {/* {data?.flatMap((pageData) =>
                  pageData?.data?.map((item: TProduct) => (
                     <ProductItem
                        selectedProduct={selectedProduct}
                        item={item}
                        key={item._id}
                        onSelectedProductClick={onSelectedProductClick}
                     />
                  ))
               )} */}
               {isInitialLoad && products?.length
                  ? similarData?.data?.map((item) => (
                       <ProductItem
                          selectedProduct={selectedProduct}
                          item={item}
                          key={item._id}
                          onSelectedProductClick={onSelectedProductClick}
                       />
                    ))
                  : data?.flatMap((pageData) =>
                       pageData?.data?.map((item: TProduct) => (
                          <ProductItem
                             selectedProduct={selectedProduct}
                             item={item}
                             key={item._id}
                             onSelectedProductClick={onSelectedProductClick}
                          />
                       ))
                    )}
               <li
                  ref={ref}
                  style={{
                     visibility: 'hidden',
                     display: 'flex',
                     height: '100px',
                     width: '100%'
                  }}>
                  <div>Loading</div>
               </li>
            </ProductsList>

            {loading && (
               <PreloaderContainer>
                  <RelativePreloader size="50px" loading />
               </PreloaderContainer>
            )}
            <BottomWrap>
               <CloseBtn onClick={close}>{t('cancel')}</CloseBtn>
               <ButtonSave disabled={!selectedProduct} onClick={onAddClick}>
                  {t('add')}
               </ButtonSave>
            </BottomWrap>
         </ModalproductsWrap>
      )
   }
)
