import { t } from 'i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTypedSelector } from 'hooks'
import { getMainProductSelector, productActions } from 'store'
import { IoClose } from 'react-icons/io5'

import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   ModalRelatedProducts,
   PlusIcon,
   SelectProductPopup,
   SubPagesBar,
   TSection
} from 'components'
import { colors } from 'enums'
import { RelatedProduct } from './components'
import { Container } from './styled'

export const RelatedProducts: FC = () => {
   const [similarProducts, setSimilarProducts] = useState<any[]>([])
   const [ProductPopupInfo, setProductPopupInfo] = useState({
      isOpen: false,
      index: null as null | number
   })

   const GRID = 8

   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { product, similar, loading, products } =
      useTypedSelector(getMainProductSelector)

   const { id, pageNumber } = useParams()

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      padding: GRID,
      overflow: 'auto',
      flex: 1
   })

   useEffect(() => {
      Request.getSimilarProducts()
   }, [])

   useEffect(() => {
      setSimilarProducts(similar)
   }, [similar])

   const Request = {
      getSimilarProducts: () => {
         product?._id &&
            dispatch(productActions.getSimilarProducts({ _id: product?._id }))
      },

      updateSimilarProducts: () => {
         const newSimilarProducts = similarProducts.map((row) => {
            return row.map((product: any, index: number) => ({
               count: index + 1,
               _id: product?._id?._id
            }))
         })

         dispatch(
            productActions.updateSimilarProducts({
               similar: newSimilarProducts,
               _id: product?._id
            })
         )
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/products/page=${pageNumber}/${id as string}`)
      },
      saveButtonClickHandler: () => {
         Request.updateSimilarProducts()
      },
      setPopupVisiablity: (isOpen: boolean, index?: number) => {
         if (isOpen && index !== undefined) {
            setProductPopupInfo({ isOpen: true, index })
            return
         }

         setProductPopupInfo({ isOpen: false, index: null })
      },
      onDragEnd: (products: any[], result: any, index: number) => {
         // dropped outside the list
         if (!result.destination) {
            return
         }

         const items = Utils.reorder(
            products,
            result.source.index,
            result.destination.index
         )

         setSimilarProducts((state) =>
            state.map((column, colIndex) => (colIndex === index ? items : column))
         )
      },
      productPopupAddHandler: (productss: any) => {
         setSimilarProducts((prev) => {
            return prev.map((productsRow, i) => {
               if (i === ProductPopupInfo.index) {
                  return productsRow.concat(
                     productss.map((newProduct: any) => ({
                        _id: newProduct,
                        count: 1
                     }))
                  )
               }

               return productsRow
            })
         })

         setProductPopupInfo({ isOpen: false, index: null })
      },
      deleteSimilarProductHandler: (id: string, index: number) => {
         setSimilarProducts(
            similarProducts.map((item) => item.filter((row: any) => row?._id?._id !== id))
         )
      }
   }

   const Utils = {
      reorder: (row: any, startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      }
   }

   const Sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/products/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/characteristics/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('related.orders'),
            active: true
         },
         {
            title: t('variations'),
            onClickHandler: () => {
               navigate(`/variations/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('alternativeProducts'),
            onClickHandler: () => {
               navigate(`/alternativeProducts/${id as string}`)
            }
         }
      ],
      []
   )

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0 20px 0 0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   return (
      <AdminPanelContainer
         loading={!ProductPopupInfo.isOpen && loading}
         Header={
            <Header
               title={t('edit.products')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <SubPagesBar sections={Sections} />

         <Container>
            {/* Dnd for lines */}
            <>
               <DragDropContext
                  onDragEnd={(result) => {
                     if (!result.destination) return

                     const reorderedColumns = Utils.reorder(
                        similarProducts,
                        result.source.index,
                        result.destination.index
                     )

                     setSimilarProducts(reorderedColumns)
                  }}>
                  <Droppable droppableId="columns" direction="vertical" type="COLUMN">
                     {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                           {similarProducts?.map((similarProductsRow, index) => (
                              <Draggable
                                 key={`row-${index}`}
                                 draggableId={`row-${index}`}
                                 index={index}>
                                 {(provided) => (
                                    <FlexContainer
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                       align="flex-start"
                                       gap="30px"
                                       width="100%"
                                       wrap="nowrap"
                                       padding="30px"
                                       key={similarProductsRow[0]?.id?._id}>
                                       <FlexContainer
                                          width="auto"
                                          direction="column"
                                          gap="15px">
                                          <Button
                                             onClick={() =>
                                                Events.setPopupVisiablity(true, index)
                                             }>
                                             <PlusIcon />
                                             {t('add')}
                                          </Button>
                                          {similarProductsRow?.length ? (
                                             <Button
                                                theme="red"
                                                onClick={() =>
                                                   setSimilarProducts((prev) =>
                                                      prev.filter((_, i) => i !== index)
                                                   )
                                                }>
                                                <IoClose size={24} />
                                                {t('delete')}
                                             </Button>
                                          ) : null}
                                       </FlexContainer>

                                       {/* DnD for products */}
                                       <DragDropContext
                                          onDragEnd={(result) =>
                                             Events.onDragEnd(
                                                similarProductsRow,
                                                result,
                                                index
                                             )
                                          }>
                                          <Droppable
                                             droppableId={`droppable-${index}`}
                                             direction="horizontal">
                                             {(provided, snapshot) => (
                                                <div
                                                   ref={provided.innerRef}
                                                   style={getListStyle(
                                                      snapshot.isDraggingOver
                                                   )}
                                                   {...provided.droppableProps}>
                                                   {similarProductsRow?.map(
                                                      (
                                                         similarProduct: any,
                                                         index: number
                                                      ) => (
                                                         <Draggable
                                                            key={similarProduct?._id?._id}
                                                            draggableId={
                                                               similarProduct?._id?._id
                                                            }
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                               <div
                                                                  ref={provided.innerRef}
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                                  style={getItemStyle(
                                                                     snapshot.isDragging,
                                                                     provided
                                                                        .draggableProps
                                                                        .style,
                                                                     similarProductsRow.lenght
                                                                  )}>
                                                                  <RelatedProduct
                                                                     similarProduct={
                                                                        similarProduct
                                                                     }
                                                                     rowIndex={index}
                                                                     deleteProducthandler={(
                                                                        id: string,
                                                                        index: number
                                                                     ) => {
                                                                        Events.deleteSimilarProductHandler(
                                                                           id,
                                                                           index
                                                                        )
                                                                     }}
                                                                  />
                                                               </div>
                                                            )}
                                                         </Draggable>
                                                      )
                                                   )}
                                                   {provided.placeholder}
                                                </div>
                                             )}
                                          </Droppable>
                                       </DragDropContext>
                                    </FlexContainer>
                                 )}
                              </Draggable>
                           ))}
                           {provided.placeholder}
                        </div>
                     )}
                  </Droppable>
               </DragDropContext>
               {ProductPopupInfo.isOpen && (
                  <ModalRelatedProducts
                     close={() => Events.setPopupVisiablity(false)}
                     setProducts={Events.productPopupAddHandler}
                     index={ProductPopupInfo.index || 0}
                     parrentProducts={similarProducts}
                  />
               )}
            </>

            <FlexContainer justify="center" padding="20px">
               <Button
                  onClick={() => {
                     setSimilarProducts((prev) => [...(prev || []), []])
                  }}>
                  <PlusIcon />
                  {t('addLine')}
               </Button>
            </FlexContainer>
         </Container>
      </AdminPanelContainer>
   )
}
