import { useTranslation } from 'react-i18next'
import { TFilterParams, TModalproductsProps } from './types'
import { useTypedSelector } from 'hooks'
import { useEffect, useState } from 'react'
import { TProduct } from 'store/product/types'
import { defaultParams, getLastElement, useGetSimilars } from './helpers'
import { useInView } from 'react-intersection-observer'
import { i18n } from 'config'
import locales from 'enums/locales'
import { useGetInfiniteProduct } from 'store'
import { BottomWrap, CloseBtn, ModalproductsWrap, Overlay, ProductsList } from './styled'
import { ProductFilterBar, ProductItem } from './components'
import { ButtonSave } from 'pages/Calculator/Pages/CreateCalculator/styled'
import { PreloaderContainer } from 'components/SelectProductPopup/styled'
import { RelativePreloader } from 'components'

export const ModalRelatedProducts = ({
   close,
   parrentProducts,
   index,
   setProducts
}: TModalproductsProps) => {
   const { t } = useTranslation()
   const parentProduct = getLastElement(parrentProducts, index)

   const { accessToken } = useTypedSelector((state) => state.user)
   const [selectedProduct, setSelectedProduct] = useState<TProduct[] | null>(null)
   const [isInitialLoad, setIsInitialLoad] = useState(!!parentProduct)

   const [params, setParams] = useState<TFilterParams>({
      ...defaultParams
   })

   const { ref, inView } = useInView({
      threshold: 0.1
   })

   const { data, isLoading, setSize, mutate } = useGetInfiniteProduct({
      params: {
         limit: 20,
         lang: i18n.language as locales,
         order: params.order,
         sortBy: params.sortBy,
         query: params.query || undefined,
         date_start: params.date_start,
         date_end: params.date_end,
         token: accessToken || '',
         category: params.category?.value ? [params.category?.value] : undefined,
         page: 1
      },
      revalidateOnMount: !isInitialLoad
   })
   // get similar products

   const { similarData, similarLoading } = useGetSimilars({
      token: accessToken || '',
      lang: i18n.language as locales,
      _id: parentProduct?._id?._id || ''
   })

   useEffect(() => {
      if (inView) {
         setSize((prevSize) => prevSize + 1)
      }
   }, [inView])

   // clear params before exit
   useEffect(() => {
      return () => {
         setSize(0)
         setSelectedProduct(null)
         setParams(defaultParams)
      }
   }, [])

   const onSelectedProductClick = (product: TProduct) => {
      if (!!selectedProduct?.find((el) => el._id === product._id)) {
         setSelectedProduct((prev) => prev?.filter((el) => el._id !== product._id) || [])
      } else {
         setSelectedProduct((prev) => [...(prev || []), product])
      }
   }
   const loading = isLoading || similarLoading

   return (
      <Overlay>
         <ModalproductsWrap>
            {' '}
            <h2>{t('addProduct')}</h2>
            <ProductFilterBar
               visibleCategory={true}
               setIsInitialLoad={setIsInitialLoad}
               mutate={mutate}
               params={params}
               setParams={setParams}
            />
            <ProductsList>
               {' '}
               {isInitialLoad
                  ? similarData?.data?.map((item) => (
                       <ProductItem
                          selectedProduct={
                             selectedProduct?.find((el) => el._id === item._id) || null
                          }
                          item={item}
                          key={item._id}
                          onSelectedProductClick={onSelectedProductClick}
                       />
                    ))
                  : data?.flatMap((pageData) =>
                       pageData?.data?.map((item: TProduct) => (
                          <ProductItem
                             selectedProduct={
                                selectedProduct?.find((el) => el._id === item._id) || null
                             }
                             item={item}
                             key={item._id}
                             onSelectedProductClick={onSelectedProductClick}
                          />
                       ))
                    )}
               <li
                  ref={ref}
                  style={{
                     visibility: 'hidden',
                     display: 'flex',
                     height: '100px',
                     width: '100%'
                  }}>
                  <div>Loading</div>
               </li>
            </ProductsList>
            {loading && (
               <PreloaderContainer>
                  <RelativePreloader size="50px" loading />
               </PreloaderContainer>
            )}
            <BottomWrap>
               <CloseBtn onClick={close}>{t('cancel')}</CloseBtn>
               <ButtonSave
                  disabled={!selectedProduct?.length}
                  onClick={() => setProducts(selectedProduct || [])}>
                  {t('add')}
               </ButtonSave>
            </BottomWrap>
         </ModalproductsWrap>
      </Overlay>
   )
}
