import { TSection } from 'components'
import { t } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

export const sections = (
   navigate: NavigateFunction,
   isStatistic: boolean,
   pageNumber: string,
   productId: string
): TSection[] => [
   {
      title: t('main.data'),
      onClickHandler: () => {
         navigate(
            isStatistic
               ? `/statistic/page=${pageNumber}/${productId}`
               : `/products/page=${pageNumber}/${productId}`
         )
      }
   },
   {
      title: t('characteristics'),
      onClickHandler: () => {
         navigate(
            isStatistic
               ? `/statisticCharacteristics/page=${pageNumber}/${productId}`
               : `/characteristics/page=${pageNumber}/${productId}`
         )
      }
   },
   {
      title: t('related.orders'),
      onClickHandler: () => {
         navigate(
            isStatistic
               ? `/statisticRelated/page=${pageNumber}/${productId}`
               : `/related/page=${pageNumber}/${productId}`
         )
      }
   },
   {
      title: t('variations'),
      onClickHandler: () => {
         navigate(
            isStatistic
               ? `/statisticVariations/page=${pageNumber}/${productId}`
               : `/variations/page=${pageNumber}/${productId}`
         )
      }
   },
   {
      title: t('alternativeProducts'),
      active: true
   }
]
