import axios, { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { PRODUCT_URL } from './config'
import {
   TAddRecomended,
   TCreatePhotoProductRequestPayload,
   TEditProductRequestPaylaod,
   TEditPromotionProductsRequestPayload,
   TGetProductRequestPayload,
   TGetProductsRequestPayload,
   TGetProductsV1RequestPayload,
   TGetRecommendedRequest,
   TGetSimilarProductsRequestPayload,
   TGetStatFileNameRequestPayload,
   TGetSubCategoriesRequestPayload,
   TGetVariationsByCategoryRequestPayload,
   TGetVariationsRequestPayload,
   TRemoveProductRequestPayload,
   TUpdateProductRequestPayload,
   TgetSimilarRes
} from './types'
import useSWRInfinite from 'swr/infinite'

export class ApiProductService extends HttpService {
   static getProducts({
      token,
      limit = 10,
      page = 0,
      lang = ELocales.ru,
      ...props
   }: TGetProductsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getProducts}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         },
         params: {
            limit,
            skip: page * limit,
            lang,
            ...props
         }
      })

      return response
   }

   static editProduct({
      token,
      _id,
      data
   }: TEditProductRequestPaylaod): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.editProduct}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static createPhotoProduct({
      data,
      token
   }: TCreatePhotoProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request<TResponse>({
         url: PRODUCT_URL.createPhotoProduct,
         method: 'POST',
         data: data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
   }

   static getProduct({
      token,
      _id,
      ...params
   }: TGetProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getProduct}/${_id}`,
         method: 'GET',
         params,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static updateProduct({
      token,
      _id,
      data
   }: TUpdateProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getProduct}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static getSimilarProducts({
      token,
      _id,
      ...params
   }: TGetSimilarProductsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.similarProducts(_id)}`,
         method: 'GET',
         params,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static async getAsyncSimilarProducts({
      token,
      _id,
      ...params
   }: TGetSimilarProductsRequestPayload): Promise<TgetSimilarRes> {
      const response = await this.request({
         url: `${PRODUCT_URL.getRecommendedProduct(_id)}`,
         method: 'GET',
         params,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response.data
   }

   static setSimilarProducts({
      token,
      _id,
      data
   }: TUpdateProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.similarProducts(_id)}`,
         method: 'POST',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static updateSimilarProducts({
      token,
      _id,
      data
   }: any): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.similarProducts(_id)}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static getVaraitions({
      token,
      field,
      value,
      lang = ELocales.ru,
      limit = 0,
      page = 0
   }: TGetVariationsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getVariations}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            field,
            value,
            lang,
            skip: limit * page
         }
      })

      return response
   }

   static getVaraitionsByCategory({
      group,
      category,
      token,
      lang = ELocales.ru,
      limit = 0,
      page = 0
   }: TGetVariationsByCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getVariationsByCategory}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            group,
            category,
            lang,
            skip: limit * page
         }
      })

      return response
   }

   static removeProduct({
      token,
      _id
   }: TRemoveProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.removeProduct}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static getSubCategories({
      token,
      _id,
      limit,
      lang = ELocales.ru
   }: TGetSubCategoriesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getCategoriesBySections}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            field: 'parent',
            value: _id,
            limit,
            lang
         }
      })

      return response
   }

   static editPromotionProducts({
      token,
      data
   }: TEditPromotionProductsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.editPromotionProducts}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static getProductsV1({
      token,
      value,
      field,
      lang,
      page,
      limit,
      ...props
   }: TGetProductsV1RequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${PRODUCT_URL.getProductsV1}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            value,
            field,
            lang,
            skip: page * limit,
            limit,
            ...props
         }
      })

      return response
   }

   static getStatFileName({
      token
   }: TGetStatFileNameRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${PRODUCT_URL.getStatFileName}`,
         method: 'POST',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
   }

   static async getRecommended({
      token,
      _id,
      lang
   }: TGetRecommendedRequest['payload']): Promise<TGetRecommendedRequest['response']> {
      const response = await this.request({
         url: `${PRODUCT_URL.getRecommendedProduct(_id)}?lang=${lang}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
      return response.data
   }
   static async addRecommended({
      token,
      _id,
      lang,
      recommended
   }: TAddRecomended): Promise<TGetRecommendedRequest['response']> {
      const response = await this.request({
         url: `${PRODUCT_URL.getRecommendedProduct(_id)}?lang=${lang}`,
         method: 'POST',
         data: { recommended },
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
      return response.data
   }
   static async editRecommended({
      token,
      _id,
      lang,
      recommended
   }: TAddRecomended): Promise<TGetRecommendedRequest['response']> {
      const response = await this.request({
         url: `${PRODUCT_URL.getRecommendedProduct(_id)}?lang=${lang}`,
         method: 'PUT',
         data: { recommended },
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
      return response.data
   }
   static async deleteRecommended({
      token,
      _id
   }: Omit<TAddRecomended, 'recommended' | 'lang'>): Promise<
      TGetRecommendedRequest['response']
   > {
      const response = await this.request({
         url: `${PRODUCT_URL.getRecommendedProduct(_id)}`,
         method: 'DELETE',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
      return response.data
   }
}

// get products
export const useGetInfiniteProduct = ({
   params,
   revalidateOnMount
}: {
   params: TGetProductsRequestPayload
   revalidateOnMount?: boolean
}) => {
   const fetcher = async (params: TGetProductsRequestPayload) =>
      (await ApiProductService.getProducts(params)).data

   const getKey = (
      pageIndex: number,
      previousPageData: any
   ): [string, TGetProductsRequestPayload] | null => {
      if (previousPageData && !previousPageData?.data?.length) return null
      return [
         'getProducts',
         {
            ...params,

            page: pageIndex
         }
      ]
   }

   const { data, isLoading, setSize, mutate } = useSWRInfinite(
      getKey,
      ([, getParams]) => {
         if (typeof getParams === 'object') {
            return fetcher(getParams)
         }
      },
      {
         revalidateFirstPage: false,
         revalidateOnFocus: false,

         revalidateOnMount:
            typeof revalidateOnMount !== 'undefined' ? revalidateOnMount : true
      }
   )
   return { data, isLoading, setSize, mutate }
}
