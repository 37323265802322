import { colors } from 'enums'
import { t } from 'i18next'
import { DropResult } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'
import { TGetRecommendedResponse, TProduct } from 'store/product/types'
import { KeyedMutator } from 'swr'

export const onAddProducts = (
   products: TProduct[],
   productData: TGetRecommendedResponse | undefined,
   mutate: KeyedMutator<TGetRecommendedResponse>,
   closeModal: () => void
) => {
   if (!productData?.data) return

   const existingIds = new Set(productData.data.map((product) => product._id))

   const newProducts = products.filter((product) => !existingIds.has(product._id))

   if (newProducts.length > 0) {
      mutate(
         {
            meta: null,
            data: [...productData.data, ...newProducts]
         },
         false
      )
      closeModal()
   } else {
      toast.info(t('allproductsAdded'))
   }
}

export const onDragEnd = (
   result: DropResult,
   productData: TGetRecommendedResponse | undefined,
   mutate: KeyedMutator<TGetRecommendedResponse>
) => {
   const { source, destination } = result

   if (!destination || destination.index === source.index) {
      return
   }

   const reorderedProducts = Array.from(productData?.data || [])

   const [movedItem] = reorderedProducts.splice(source.index, 1)

   reorderedProducts.splice(destination.index, 0, movedItem)

   mutate(
      {
         meta: null,
         data: reorderedProducts
      },
      false
   )
}

export const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
   userSelect: 'none',
   margin: `0 20px 0 0`,
   background: colors.solid_white,

   ...draggableStyle
})
export const getListStyle = (isDraggingOver: boolean) => ({
   display: 'flex',
   padding: 8,
   overflow: 'auto',
   flex: 1
})

export const onDeleteProduct = (
   product: TProduct,
   productData: TGetRecommendedResponse | undefined,
   mutate: KeyedMutator<TGetRecommendedResponse>
) => {
   if (!productData?.data) return
   mutate(
      {
         meta: null,
         data: [...productData.data.filter((el) => el._id !== product._id)]
      },
      false
   )
}
