import { colors } from 'enums'
import { ControlProps, CSSObjectWithLabel } from 'react-select'
import styled from 'styled-components'

export const Image = styled.img`
   width: 46px;
   height: 46px;
`

export const PreloaderContainer = styled.div`
   width: 100%;
   height: 100px;
`

export const TableContainer = styled.div`
   height: 560px;
   overflow-y: auto;
`
export const selectStyles = {
   control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<{ label: string; value: string }>
   ) => ({
      ...provided,
      backgroundColor: 'transparent',
      width: '273px',
      height: '44px',
      borderRadius: '8px',
      boxShadow: 'none',
      border:
         state.isFocused || state.menuIsOpen
            ? `1px solid ${colors.black}`
            : `1px solid ${colors.lighter_grey}`,
      '&:hover': {
         border: state.isFocused || state.menuIsOpen ? '1px solid #222222' : undefined
      }
   }),
   dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided
      // display: 'none'
   }),
   indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none'
   })
}
