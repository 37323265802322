import {
   TGetRecommendedPayload,
   TGetRecommendedResponse,
   TProduct
} from 'store/product/types'
import { TFilterParams } from './types'
import { ApiProductService } from 'store'
import useSWR from 'swr'
import { useMemo } from 'react'

export const defaultParams: TFilterParams = {
   date_start: '',
   date_end: '',
   query: '',
   category: undefined,
   sortBy: 'createdAt',
   order: -1
   // page: 0
}

export const useGetSimilars = (params: TGetRecommendedPayload) => {
   const { data: similarData, isLoading: similarLoading } = useSWR(
      params._id ? ['getSimilarProducts', params] : null,
      ([, getParams]) => ApiProductService.getRecommended(getParams),

      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { similarData, similarLoading }
}

export const useGetSimilarData = (
   parrentProduct: TProduct,
   similarData?: TGetRecommendedResponse
) => {
   const initialSimilarData = parrentProduct

   const processedData = useMemo(() => {
      return similarData?.data?.length
         ? similarData?.data?.find((el) => el._id === initialSimilarData._id)
            ? similarData?.data
            : [...similarData?.data, initialSimilarData]
         : [initialSimilarData]
   }, [similarData])
   return { processedData }
}

export const getLastElement = (
   parentProducts: { count: number; _id: TProduct }[][],
   index: number
) => {
   const array = parentProducts[index]

   if (!array || array.length === 0) {
      return undefined
   }

   if (array.length === 1) {
      return array[0]
   }

   return array[array.length - 1]
}
