import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   PlusIcon,
   SubPagesBar
} from 'components'
import { useTypedSelector } from 'hooks'
import Moment from 'react-moment'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getMainProductSelector } from 'store'
import { sections } from './consts'
import { ModalRelatedProducts } from 'components'
import useSWR from 'swr'
import { ApiProductService } from 'store'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { TProduct } from 'store/product/types'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { RelatedProduct } from '../RelatedProducts/components'
import { toast } from 'react-toastify'
import {
   getItemStyle,
   getListStyle,
   onAddProducts,
   onDeleteProduct,
   onDragEnd
} from './helpers'

export const AlternativeProducts = () => {
   const navigate = useNavigate()
   const { t, i18n } = useTranslation()
   const lang = i18n.language
   const [openModal, setOpenModal] = useState(false)
   const [initialDataEmpty, setInitialDataEmpty] = useState<null | boolean>(null)
   const { product } = useTypedSelector(getMainProductSelector)

   const { id, pageNumber } = useParams()
   const { search } = useLocation()
   const isStatistic = search.includes('statistic')
   const { accessToken } = useTypedSelector((state) => state.user)

   const params = { lang, _id: id || '', token: 'Bearer ' + accessToken || '' }
   const {
      data: productData,
      mutate,
      isLoading
   } = useSWR(
      ['getAlternativeProducts', params],
      ([, par]) => ApiProductService.getRecommended(par),
      { keepPreviousData: true, revalidateOnFocus: false }
   )

   const closeModal = () => setOpenModal(false)
   const openModaClick = () => {
      setOpenModal(true)
   }

   useEffect(() => {
      if (productData && initialDataEmpty === null) {
         setInitialDataEmpty(productData.data.length === 0)
      }
   }, [productData, initialDataEmpty])

   const onSaveClick = async () => {
      const params = {
         lang,
         token: 'Bearer ' + accessToken || '',
         _id: id || '',
         recommended: [...(productData?.data?.map((item) => item._id) || [])]
      }
      try {
         if (initialDataEmpty) {
            await ApiProductService.addRecommended(params)
         } else {
            await ApiProductService.editRecommended(params)
         }

         toast.success(t('changesSaved'))
      } catch (error) {
         toast.error('Error')
      }
   }

   return (
      <AdminPanelContainer
         loading={isLoading}
         Header={
            <Header
               title={t('edit.products')}
               backButtonClickHandler={() => navigate(-1)}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {product ? new Date(product?.createdAt as string) : ''}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {product ? new Date(product?.updatedAt as string) : ''}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     <Button
                        disabled={isLoading}
                        theme="green"
                        height={46}
                        onClick={onSaveClick}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <SubPagesBar
            sections={sections(navigate, isStatistic, pageNumber || '0', id || '')}
         />
         <FlexContainer
            align="flex-start"
            gap="30px"
            width="100%"
            wrap="nowrap"
            padding="30px">
            <FlexContainer width="auto" direction="column" gap="15px">
               <Button onClick={openModaClick}>
                  <PlusIcon />
                  {t('add')}
               </Button>
            </FlexContainer>
         </FlexContainer>
         <DragDropContext onDragEnd={(res) => onDragEnd(res, productData, mutate)}>
            <Droppable droppableId="products-droppabl" direction="horizontal">
               {(provided, snapshot) => (
                  <div
                     ref={provided.innerRef}
                     style={getListStyle(snapshot.isDraggingOver)}
                     {...provided.droppableProps}>
                     {productData?.data?.map((product, index: number) => (
                        <Draggable
                           key={product._id}
                           draggableId={product._id}
                           index={index}>
                           {(provided, snapshot) => (
                              <div
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                                 style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    productData?.data?.length || 0
                                 )}>
                                 <RelatedProduct
                                    similarProduct={{ _id: product }}
                                    rowIndex={index}
                                    deleteProducthandler={() =>
                                       onDeleteProduct(product, productData, mutate)
                                    }
                                 />
                              </div>
                           )}
                        </Draggable>
                     ))}
                     {provided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
         {openModal && (
            <ModalRelatedProducts
               parrentProducts={[
                  productData?.data?.map((item) => ({ count: 1, _id: item })) || []
               ]}
               close={closeModal}
               index={0}
               setProducts={(products) =>
                  onAddProducts(products, productData, mutate, closeModal)
               }
            />
         )}
      </AdminPanelContainer>
   )
}
