import { colors } from 'enums'
import styled from 'styled-components'

export const Overlay = styled.div`
   position: absolute;
   left: 0;
   top: 0;
   z-index: 5;
   width: 100vw;
   height: 100%;
   background-color: rgba(99, 123, 150, 0.6);
`

export const ModalproductsWrap = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #ffffff;
   border-radius: 16px;
   width: 1158px;
   padding: 20px 30px;
   overflow: auto;
   display: flex;
   align-items: center;
   flex-direction: column;
   max-height: 98%;
   height: auto;
   overflow: auto;
   z-index: 6;
`
export const Title = styled.h2`
   margin: 0 auto 30px;
   display: flex;
`
export const ProductsList = styled.ul`
   margin-top: 20px;
   list-style: none;
   display: flex;
   flex-direction: column;
   width: 100%;
   /* max-height: 511px; */
   overflow: auto;
`

export const CloseBtn = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: transparent;
   border: 1px solid ${colors.grey};
   text-decoration: none;
   cursor: pointer;
   font-size: 16px;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   color: ${colors.grey};
   transition: background-color 0.3s;
   &:hover {
      background-color: ${colors.light_grey};
   }
`
export const BottomWrap = styled.div`
   display: flex;
   align-items: center;
   gap: 30px;
   margin-top: auto;
   padding: 20px 0 0;
`
