import { FC } from 'react'
import { StyledButton } from './styled'
import { buttonTheme, TButtonProps } from './types'

const Button: FC<TButtonProps> = ({
   disabled,
   children,
   theme = 'blue',
   style = 'basic',
   onClick,
   width = 195,
   height = 49
}) => {
   return (
      <StyledButton
         disabled={disabled}
         className={`${theme} ${style}`}
         onClick={onClick}
         width={width}
         height={height}>
         {children}
      </StyledButton>
   )
}

export default Button
